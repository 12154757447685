export const delay = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const addMonth = (date) => {
    const selectedDate = new Date(date);
    const oneMonthFromSelected = new Date(selectedDate);
    oneMonthFromSelected.setMonth(selectedDate.getMonth() + 1);
    const formattedDate = oneMonthFromSelected.toISOString().split('T')[0];
    return formattedDate;
};

export const process = async (url, option) => {
    const response = await fetch(url, option);
    if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
    const data = await response.json();
    return data;
};

export const prepareDate = () => {
    const currentDate = new Date();
  
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
  
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
  
    const selectedDate = `${year}-${formattedMonth}-${formattedDay}`;
    return selectedDate;
};