import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../Hooks/useNotify';
import { useAuthorization } from '../../Hooks/useAuthorization';
import { delay } from '../../Configs/utils';

import Header from '../../Components/Header/Header';
import Form from '../../Components/Form/Form';
import FormRow from "../../Components/Form/FormRow";
import TextInput from '../../Components/TextInput/TextInput';
import Button, { ButtonType, ButtonColor, ButtonShape, ButtonSize } from '../../Components/Button/Button';
import PuromedLoginPhoto from "../../Resources/PuromedLoginPhoto.png";
import { SignPage } from '../../Components/SignPage/SignPage';
import ExtraActions from '../../Components/ExtraActions/ExtraActions';


export const LoginForm = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const { signin } = useAuthorization();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { contextHolder, notify } = useNotify();

    const submit = async () => {
        try{
            const response = await signin(login, password);
            notify('success', 'Brawo :)', 'Proces uwierzytelniania przebiegł pomyślnie.');
            await delay(5000); navigate("/profile");
        }catch(error){
            console.log('Error(login.submit)', error.message);
            notify('error', 'Coś poszło nie tak :(', 'Uwierzytelnienie użytkownika nie powiodło się.');
        }
    };

    const formHeader = (
        <Header
            level={3}
            title={t('sign_in')}
            subtitle={t('enter_your_data')}
        />
    );

    return (
        <Form id="login" header={formHeader} onSubmit={submit}>
            {contextHolder}
            <FormRow>
                <TextInput label={t('email')} placeholder={t('email_input_placeholder')} value={login} onChange={(e) => setLogin(e.target.value)}/>
            </FormRow>
            <FormRow>
                <TextInput label={t('password')} placeholder={t('password_input_placeholder')} type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
            </FormRow>
            <FormRow>
                <Button
                    type={ButtonType.Filled}
                    color={ButtonColor.Primary}
                    shape={ButtonShape.Rounded}
                    text={t('sign_in_action')}
                    submit
                />
            </FormRow>
            <FormRow>
                <ExtraActions label={t('dont_have_account')} buttonLabel={t('sign_up_action')} onClick={() => navigate("/registration")}/>
            </FormRow>
        </Form>
    );
}

export const Login = () => {
    return (
        <React.Fragment>
            <SignPage form={<LoginForm />} photo={PuromedLoginPhoto} />
        </React.Fragment>
    );
}