import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../Hooks/useNotify';
import { useAuthorization } from '../../Hooks/useAuthorization';

import { Page } from '../../Components/Page/Page';
import Header from '../../Components/Header/Header';
import SelectInput from '../../Components/Select/SelectInput';
import Form from '../../Components/Form/Form';
import FormRow from "../../Components/Form/FormRow";
import TextInput from '../../Components/TextInput/TextInput';
import FormRowItem from '../../Components/Form/FormRowItem';
import SidebarMenu from '../../Components/SiderbarMenu/SidebarMenu';
import Button, { ButtonType, ButtonColor, ButtonShape } from '../../Components/Button/Button';
import Checkbox from '../../Components/Checkbox/Checkbox';
import { Navbar } from '../../Components/Navbar/Navbar';
import { Footer } from '../../Components/Footer/Footer';
import DatePicker from '../../Components/DatePicker/DatePicker';

const Profile = ({ }) => {
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [changePassword, setChangePassword] = useState(false);
    
    const { user, update, edit } = useAuthorization();
    const location = useLocation();
    const { contextHolder, notify } = useNotify();
    const { t } = useTranslation();

    const submit = async () => {
        try{
            if(password !== repassword) { throw new Error('Brak zgodności haseł.'); }
            const response = await edit(user, password);
            notify('success', 'Brawo :)', 'Proces edycji przebiegł pomyślnie.');
        }catch(error){
            console.log('Error (profile.submit):', error);
            notify('error', 'Ups :(', 'Coś poszło nie tak ...');
        }
    };

    const pageTitle = (
        <Header
            title={t(`patient_profile`)}
            subtitle={""}
            icon={<box-icon name='user-circle' ></box-icon>}
        />
    );

    const formHeader = (
        <Header
            level={3}
            title={t(`patient_data`)}
            subtitle={t(`patient_data_description`)}

        />
    );

    const mainPart = (
        <Form header={formHeader} onSubmit={submit}>
            <FormRow>
                <TextInput label={t(`name`)} placeholder={t(`name_input_placeholder`)} name='name' value={user.name} onChange={update} />
                <TextInput label={t(`surname`)} placeholder={t(`surname_input_placeholder`)} name='surname' value={user.surname} onChange={update}/>
            </FormRow>
            <FormRow>
                <TextInput label={t(`phone`)} placeholder={t(`phone_input_placeholder`)} name='phone' value={user.phone} onChange={update}/>
                <TextInput label={t(`email`)} placeholder={t(`email_input_placeholder`)} name='email' value={user.email} onChange={update}/>
            </FormRow>
            <FormRow>
                <TextInput label={t(`pesel`)} placeholder={t(`pesel_input_placeholder`)} name='pesel' value={user.pesel} onChange={update} disabled='true'/>
            </FormRow>
            {/* <FormRow>
                <DatePicker id={5} label={t(`date_of_birth`)} placeholder={t(`date_of_birth_placeholder`)}></DatePicker>
            </FormRow> */}
            <FormRow>
                <FormRowItem flexBasis={60}>
                    <TextInput label={t(`street`)} placeholder={t(`street_input_placeholder`)} name='street' value={user.street} onChange={update}/>
                </FormRowItem>
                <FormRowItem flexBasis={30}>
                    <TextInput label={t(`home`)} placeholder={t(`home_input_placeholder`)} name='home' value={user.home} onChange={update}/>
                </FormRowItem>
                <FormRowItem flexBasis={10}>
                    <TextInput label={t(`apartment`)} placeholder={t(`apartment_input_placeholder`)} name='apartment' value={user.apartment} onChange={update}/>
                </FormRowItem>
            </FormRow>
            <FormRow>
                <FormRowItem>
                    <TextInput label={t(`postal_code`)} placeholder={t(`postal_code_input_placeholder`)} name='code' value={user.code} onChange={update}/>
                </FormRowItem>
                <FormRowItem flexBasis={75}>
                    <TextInput label={t(`city`)} placeholder={t(`city_input_placeholder`)} name='city' value={user.city} onChange={update}/>
                </FormRowItem>
            </FormRow>
            <FormRow>
                <Checkbox label={t(`change_password`)} value={changePassword} onChange={() => setChangePassword(!changePassword)} />
            </FormRow>
            {changePassword &&
                <FormRow>
                    <TextInput label={t(`change_password`)} placeholder={t(`password_input_placeholder`)} value={password} onChange={(e) => setPassword(e.target.value)}/>
                </FormRow>}
            {changePassword &&
                <FormRow>
                    <TextInput label={t(`password_repeat`)} placeholder={t(`password_repeat_placeholder`)} value={repassword} onChange={(e) => setRepassword(e.target.value)}/>
                </FormRow>}
            <Button
                type={ButtonType.Filled}
                color={ButtonColor.Primary}
                shape={ButtonShape.Pill}
                text={t(`submit_changes`)}
                submit
            />
        </Form>
    );

    return (
        <React.Fragment>
            {contextHolder}
            <Navbar profileMenu navbarButton currentPath={location.pathname} />
            <Page id="profile" pageTitle={pageTitle} mainPart={mainPart} sidebarMenu={<SidebarMenu currentPath={location.pathname} />} />
            <Footer />
        </React.Fragment>
    );
};

export default Profile;
