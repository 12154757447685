import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useClinic } from "../../Hooks/useClinic";
import { useService } from "../../Hooks/useService";
import { useDoctor } from "../../Hooks/useDoctor";
import { useTranslation } from "react-i18next";
import { useDate } from "../../Hooks/useDate";
import { useAppointment } from "../../Hooks/useAppointment";
import { useNotify } from "../../Hooks/useNotify";

import { Page } from "../../Components/Page/Page";
import Header from "../../Components/Header/Header";
import SelectInput from "../../Components/Select/SelectInput";
import Card from "../../Components/Card/Card";
import Divider from "../../Components/Divider/Divider";
import ToggleButton from "../../Components/ToggleButton/ToggleButton";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { Navbar } from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner";
import EmptyPlaceholder from "../../Components/EmptyPlaceholder/EmptyPlaceholder";
import { CloseOutlined } from '@ant-design/icons';
import { FloatButton, Select } from "antd";

export default function BookAppointment() {

    const [visitType, setVisitType] = useState(false);
    const [clinic, setClinic] = useState({ value: "", label: "" });
    const [service, setService] = useState({ value: "", label: "" });
    const [doctor, setDoctor] = useState({ value: "", label: "" });
    const [date, setDate] = useDate();

    const clinics = useClinic(service.value, doctor.value, visitType);
    const services = useService(clinic.value, doctor.value, visitType);
    const doctors = useDoctor(clinic.value, service.value, visitType); 

    const [showClinicBanner, setShowClinicBanner] = useState(true);
    const [showServiceBanner, setShowServiceBanner] = useState(true);

    const {appointments, setAppointments, fetchAppointment} = useAppointment();
    const {contextHolder, notify} = useNotify();
    const params = useParams();
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [render, setRender] = useState(0);

    const initializeValues = () => {
        const { clinicId, serviceId, doctorId } = params;

        if (clinicId) {
            const selectedClinic = clinics.find(x => x.value == clinicId);
            if (selectedClinic) setClinic(selectedClinic);
        }

        if (serviceId) {
            const selectedService = services.find(x => x.value == serviceId);
            if (selectedService) setService(selectedService);
        }

        if (doctorId) {
            const selectedDoctor = doctors.find(x => x.value == doctorId);
            if (selectedDoctor) setDoctor(selectedDoctor);
        }

        if (clinicId && serviceId && doctorId) {
            handleBanners(true, true);
            searchAppointment({ clinic: clinicId, doctor: doctorId, service: serviceId, date });
        }
    };

    useEffect(() => {
        if (clinics.length && services.length && doctors.length && render == 0) {
            setRender(1);
            initializeValues();
        }
    }, [clinics, services, doctors]);

    const selectClinic = (e) => {
        try {
            setClinic(e || { value: "", label: "" });
            searchAppointment({ clinic: e?.value || "", doctor: doctor?.value || "", service: service?.value || "", date: date });
        } catch (error) {
            console.log("Error(selectClinic): ", error.message);
            notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.');
        }        
    };

    const selectService = (e) => {
        try{
            setService(e || { value: "", label: "" });
            searchAppointment({ clinic: clinic?.value || "", doctor: doctor?.value || "", service: e?.value || "", date: date });
        } catch (error) {
            console.log("Error(selectService): ", error.message);
            notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.');
        }  
    };

    const selectDoctor = (e) => {
        try{
            setDoctor(e || { value: "", label: "" });
            searchAppointment({ clinic: clinic?.value || "", doctor: e?.value || "", service: service?.value || "", date: date }); 
        } catch (error) {
            console.log("Error(selectDoctor): ", error.message);
            notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.');
        } 
    }; 

    const selectDate = (e) => {
        try{
            setDate(e.target?.value || "");
            searchAppointment({ clinic: clinic?.value || "", doctor: doctor?.value || "", service: service?.value || "", date: e.target?.value || "" });
        } catch (error) {
            console.log("Error(selectDate): ", error.message);
            notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.');
        } 
    };

    const selectVisitType = (e) => { 
        try{
            setVisitType(e); //TODO
        } catch(error) {
            console.log("Error(selectVisitType): ", error.message);
            notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.');
        }
    };

    const searchAppointment = async ({ clinic = "", doctor = "", service = "", date = "" }) => {
        try{
            handleBanners(clinic, service);
            if(!clinic || !service || !date){ setAppointments([]); return; }
            const response = await fetchAppointment(clinic, service, doctor, date, doctors);
        } catch (error) {
            console.log("Error(searchAppointment): ", error.message);
            notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.');
        }
    };

    const chooseAppointment = (hour, date, appointment) => {
        navigate("/appointment/details", {
            state: { hour: hour, date: date, appointment: appointment },
        });
    };

    const clearAppointment = () => navigate(0);

    const handleBanners = (clinic, service) => {
        setShowClinicBanner(!clinic);
        setShowServiceBanner(!service);
    };

    const handleClearClinic = () => {
        setClinic({ value: "", label: "" });
        searchAppointment({ clinic: "", doctor: doctor?.value || "", service: service?.value || "", date });
    };

    const handleClearService = () => {
        setService({ value: "", label: "" });
        searchAppointment({ clinic: clinic?.value || "", doctor: doctor?.value || "", service: "", date });
    };

    const handleClearDoctor = () => {
        setDoctor({ value: "", label: "" });
        searchAppointment({ clinic: clinic?.value || "", doctor: "", service: service?.value || "", date });
    };
    
    const pageTitle = (
        <Header
            title={t("book_appointment")}
            subtitle={t("book_appointment_description")}
            icon={<box-icon name="calendar-check"></box-icon>}
        />
    );

    const mainPart = (
        <div className="content-wrapper">
            <ToggleButton
                id="toggleButton"
                label={t("localization")}
                button_1_label={t("stationary")}
                button_2_label={t("online")}
                onChange={selectVisitType}
                checked={visitType}
            />
            <div className="control-group" style={{flexDirection: 'column'}}>
                <label className="select__label">{t("specialization")}</label>
                <Select
                    className="select__custom"
                    allowClear
                    labelInValue
                    value={clinic}
                    onChange={selectClinic}
                    onClear={handleClearClinic}
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={clinics}
                    style={
                        {
                            width: '100%',
                            height: '45px',
                            borderRadius: "8px",
                            border: "1px solid hsl(0, 0%, 91.4%)",
                            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
                            appearance: "none"
                        }
                    }                    
                />
                <label className="select__label">{t("service")}</label>
                <Select
                    className="select__custom"
                    allowClear
                    labelInValue
                    value={service}
                    onChange={selectService}
                    onClear={handleClearService}
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={services}
                    style={
                        {
                            width: '100%',
                            height: '45px',
                            borderRadius: "8px",
                            border: "1px solid hsl(0, 0%, 91.4%)",
                            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
                            appearance: "none"
                        }
                    }  
                />
                <label className="select__label">{t("doctor")}</label>
                <Select
                    className="select__custom"
                    allowClear
                    labelInValue
                    value={doctor}
                    onChange={selectDoctor}
                    onClear={handleClearDoctor}
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={doctors}
                    style={
                        {
                            width: '100%',
                            height: '45px',
                            borderRadius: "8px",
                            border: "1px solid hsl(0, 0%, 91.4%)",
                            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
                            appearance: "none"
                        }
                    }  
                />
                {/* <SelectInput
                    functional
                    id={1}
                    label={t("specialization")}
                    placeholder={t("specialization_input_placeholder")}
                    options={clinics}
                    value={clinic}
                    onChange={selectClinic}
                />
                <SelectInput
                    functional
                    id={2}
                    label={t("service")}
                    placeholder={t("service_input_placeholder")}
                    options={services}
                    value={service}
                    onChange={selectService}
                />
                <SelectInput
                    functional
                    id={3}
                    label={t("doctor")}
                    placeholder={t("doctor_input_placeholder")}
                    options={doctors}
                    value={doctor}
                    onChange={selectDoctor}
                /> */}
                <DatePicker
                    id={5}
                    label={t("from_date")}
                    placeholder={t("from_date")}
                    value={date}
                    onChange={selectDate}
                ></DatePicker>
            </div>
            <Divider />
            <div className="cards-container">
                {showClinicBanner && <EmptyPlaceholder title={t(`no_specialization_selected`)} subtitle={t(`no_specialization_selected_description`)} />}
                {showServiceBanner && <EmptyPlaceholder title={t(`no_service_selected`)} subtitle={t(`no_service_selected_description`)} />}
                {appointments.map((appointment, index) => {
                    if (index === 0) {
                        return (
                            <Banner key={index}>  
                                <Card
                                    showPrice
                                    appointment={appointment}
                                    chooseAppointment={chooseAppointment}
                                    handleError={() => notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.')}
                                />
                            </Banner>
                        );
                    } else {
                        return (
                            <Card
                                key={index}
                                showPrice
                                appointment={appointment}
                                chooseAppointment={chooseAppointment}
                                handleError={() => notify('error', 'Coś poszło nie tak :(', 'Prosimy o kontakt z rejestracją pod numerem 42 20 80 100.')}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {contextHolder}
            <FloatButton icon={<CloseOutlined />} tooltip={<div>Wyczyść filtry</div>} onClick={clearAppointment}/>
            <Navbar profileMenu navbarButton />
            <Page
                id="bookApointment"
                pageTitle={pageTitle}
                contactCard
                mainPart={mainPart}
            />
        </React.Fragment>
    );
};