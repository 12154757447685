export class AppointmentDTO {
    constructor(data) {
        this.dates = Object.keys(data).map(date => ({
            date: date,
            poradnie: data[date].poradnie ? Object.values(data[date].poradnie).map(poradnia => new Poradnie(poradnia)) : []
        }));
    }
}

export class Poradnie {
    constructor(data) {
        this.id = data.id || null;
        this.nazwa = data.nazwa || '';
        this.specjalizacje = data.specjalizacje ? Object.values(data.specjalizacje).map(specjalizacja => new Specjalizacje(specjalizacja)) : [];
    }
}

export class Specjalizacje {
    constructor(data) {
        this.id = data.id || null;
        this.nazwa = data.nazwa || '';
        this.pracownicy = data.pracownicy ? Object.values(data.pracownicy).map(pracownik => new Pracownicy(pracownik)) : [];
    }
}

export class Pracownicy {
    constructor(data) {
        this.id = data.id || null;
        this.imie = data.imie || '';
        this.nazwisko = data.nazwisko || '';
        this.npwz = data.npwz || null;
        this.tytul = data.tytul || '';
        this.zajetyDzien = data.zajetyDzien || 0;
        this.czas = data.czas || 0;
        this.czasWizyt = data.czasWizyt || 0;
        this.limit = data.limit || 0;
        this.liczbaWizytFirmowych = data.liczbaWizytFirmowych || '0';
        this.cennikId = data.cennikId || null;
        this.usluga = data.usluga ? new Uslugi(data.usluga) : null;
        this.grafik = data.grafik || [];
        this.terminy = data.terminy || [];
    }
}

export class Uslugi {
    constructor(data) {
        this.id = data.id || null;
        this.nazwa = data.nazwa || '';
        this.kwota = data.kwota || '0';
        this.czas = data.czas || '0';
    }
}
