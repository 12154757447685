import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
const SelectInput = ({ id, label, options, onChange, value, append, disabled, placeholder, functional }) => {
    // console.log('options', options);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null)
    const menuWrapperRef = useRef(null);

    useEffect(() => {
        if (label) {
            const selected = options ? options.find(option => String(option.value) === String(value)) : null;
            if (selected != null) { setSelectedOption(selected.label); }
        } else {
            setSelectedOption(value);
        }
    }, [value, options]);


    const Options = () => {

        return (
            <div className="select__options-wrapper" >
                <option className={`select__option`} value="" onClick={() => handleOptionClick("", "Dowolna")}>Dowolna</option>
                {options.map((option) => (
                    <option className={`select__option ${option.value === selectedOption ? 'select__option--selected' : ''}`} key={option.value} value={option.value} onClick={() => handleOptionClick(option.value, option.label)}>
                        {option.label}
                    </option>
                ))}
            </div>
        );
    };

    const toggleOptions = () => {
        if (!disabled)
            setShowOptions(!showOptions);
    };

    const handleOptionClick = (optionValue, optionLabel) => {
        setSelectedOption(optionLabel);
        onChange(optionValue);
        toggleOptions();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuWrapperRef.current && !menuWrapperRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        if (showOptions) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showOptions]);

    const showPlaceholder = placeholder && selectedOption == null;

    return (
        <div className={`input-wrapper`} ref={menuWrapperRef}>
            {label && <label htmlFor={id} className='input__label'>{label}</label>}
            <div className='select__input-container' onClick={toggleOptions}>

                {!functional && <select
                    className={showPlaceholder ? 'select__input select__input--placeholder' : 'select__input'}
                    id={id}
                    onChange={onChange}
                // disabled={disabled}
                >
                    {selectedOption && <option>{selectedOption}</option>}
                    {showPlaceholder && < option className='select__option--placeholder'>{selectedOption || placeholder}</option>}
                </select>}

                {functional && <Select
                    defaultValue={value}
                    value={(options && options.find(option => option.value == value)) || null}
                    onChange={(selectedOption) => onChange(selectedOption.value)}
                    options={options}
                    isDisabled={disabled}
                    placeholder={placeholder}
                />}

                {append && <div className='select__input-container__append'>{append}</div>}
            </div>
            {/* {showOptions && <Options />} */}
        </div >
    );
};

export default SelectInput;
