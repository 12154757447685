import React from 'react';


const Divider = ({ }) => {
    return(
        <hr className='divider'/>
    );
};

export default Divider;
