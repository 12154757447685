import { useState, useEffect } from "react";
import { useStorage } from "./useStorage";
import axios from 'axios';
import { API_URL } from "../Configs/urls";

const john = {
    name: '',
    surname: '',
    pesel: '',
    email: '',
    phone: '',  
    birthday: '',
    street: '',
    home: '',
    apartment: '',
    code: '',
    city: ''
};

export const useAuthorization = () => {
    const [user, setUser] = useState(john);
    const { fetchStorage, setStorage, deleteStorage, existStorage } = useStorage();
    
    useEffect(() => {
        if(isLogged()){ setUser(extract()); }
    }, []);

    const signin = async (login, password) => {
        const response = await axios.post(`${API_URL}/auth/signin`, { login, password }); //console.log('signin', response);
        setStorage(response.data);
        return response.data;
    };

    const signup = async (name, surname, pesel, login, password) => {
        const response = await axios.post(`${API_URL}/auth/signup`, { name, surname, pesel, login, password }); //console.log('signup', response);
        return response.data;
    };

    const edit = async (user, password) => {
        const response = await axios.post(`${API_URL}/auth/edit`, { ...user, password }); //console.log('edit', response);
        setStorage(response.data); setUser(response.data);
        return response.data;
    };

    const update = (e) => setUser({ ...user, [e.target.name]: e.target.value });
    const signout = () => deleteStorage();
    const extract = () => fetchStorage();
    const isLogged = () => existStorage();

    return { user, signin, signup, signout, extract, update, isLogged, edit };
};