import React, { useState, useEffect } from 'react';
import Button, { ButtonColor, ButtonSize, ButtonType, ButtonShape } from "../Button/Button";
import { format, addDays, subDays, } from "date-fns";
import Heading from "../Heading/Heading";
import Tag from "../Tag/Tag";
import Divider from '../../Components/Divider/Divider';
import { isToday, isTomorrow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { enUS, pl } from "date-fns/locale";
import { useNavigate } from 'react-router-dom';

const HourPicker = (appointment) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [dateStep, setDateStep] = useState(5);
    const [anchorDate, setAnchorDate] = useState(new Date(appointment.appointment.dates[0].date));
    const [localeState, setLocaleState] = useState(pl);

    useEffect(() => {
        if (i18n.language === 'en') {
            setLocaleState(enUS);
        } else if (i18n.language === 'pl') {
            setLocaleState(pl);
        } else {
            setLocaleState(enUS);
        }
    }, [i18n.language]);

    const [isExpanded, setIsExpanded] = useState(false);
    const [disablePrevButton, setDisablePrevButton] = useState(true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const formatDate = (x) => {
        const year = x.getFullYear();
        const month = (x.getMonth() + 1).toString().padStart(2, '0');
        const day = x.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const chooseAppointment = (hour, date, appointment) => {        //TODO
        console.log("Hour: ", hour);
        console.log("Date: ", formatDate(date));
        console.log("Appo: ", appointment);
        navigate('/appointment/details', { state: { hour: hour, date: formatDate(date), appointment: appointment } });
    };

    useEffect(() => {
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            setWindowWidth(newWindowWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth >= 0 && windowWidth <= 390) {
            setDateStep(2);
        }
        else if (windowWidth < 501) {
            setDateStep(3);
        }
        else {
            setDateStep(5);
        }
    }, [windowWidth]);

    const checkIfInPast = (timeStamp) => {
        const currentDate = new Date();
        if (currentDate - timeStamp <= dateStep * 24 * 60 * 60 * 1000) {
            return true;
        }
        else {
            return false;
        }
    };

    const changeWeekHandle = (btnType) => {
        if (btnType === "prev") {
            const prevAnchorPoint = subDays(anchorDate, dateStep)
            if (checkIfInPast(prevAnchorPoint)) {
                setAnchorDate(prevAnchorPoint);
            }
        }
        if (btnType === "next") {
            const nextAnchorPoint = addDays(anchorDate, dateStep)
            setAnchorDate(nextAnchorPoint);
            setDisablePrevButton(false);
        }
    };


    useEffect(() => {
        const prevAnchorPoint = subDays(anchorDate, dateStep);
        if (checkIfInPast(prevAnchorPoint)) {
            setDisablePrevButton(false);
        } else {
            setDisablePrevButton(true);
        }
    }, [anchorDate]);


    const renderHeader = () => {
        return (
            <Heading level={4} text={t(`select_visit_date`)} />
        );
    };

    const renderDays = () => {
        const startDate = anchorDate;
        const dateFormat = "EEE";
        const monthFormat = "MMM";
        const days = [];
        for (let i = 0; i < dateStep; i++) {
            const dayDate = addDays(startDate, i);
            const formattedDate = format(dayDate, dateFormat, { locale: localeState });
            const dayNumber = dayDate.getDate();
            const monthName = format(dayDate, monthFormat, { locale: localeState });

            let dayLabel;
            if (isToday(dayDate)) {
                dayLabel = t(`today`);
            } else if (isTomorrow(dayDate)) {
                dayLabel = t(`tommorow`);
            }

            days.push(
                <div className="day-info" key={i}>
                    <div className='day-info-container'>
                        <span className="day-name">
                            {dayLabel ? dayLabel : formattedDate}
                        </span>
                        <span className="day-number">{dayNumber + " " + monthName}</span>
                    </div>
                </div>
            );
        }
        return (
            <div className="days-row">
                {days}
            </div>
        );
    };


    const renderCells = () => {
        const startDate = anchorDate;
        let day = startDate;
        const rows = [];
        let days = [];
        for (let i = 0; i < dateStep; i++) {
            days.push(
                <div className={`column`} >
                    {renderButtons(day)}
                </div>
            );
            day = addDays(day, 1);
        }
        rows.push(
            <div className={`row__tags-container ${isExpanded ? "row__tags-container--expanded" : ""}`} key={day.getTime()}>
                {days}
            </div>
        );
        days = [];

        return (
            <React.Fragment>
                <div className="days-columns">
                    {rows}
                </div>
            </React.Fragment>
        );
    };

    const renderButtons = (day) => {
        // const date = new Date(day);
        // const dayOfWeek = format(date, 'EEEE');

        const date = new Date(day);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const dayOfMonth = date.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${dayOfMonth}`;
        const table = [];
        const timeSlotsByDate = {};
        const formattedTimeSlotsByDate = {};

        console.log("test:", appointment);      //TODO 
        appointment.appointment.dates.forEach((entry) => {
            console.log("entry:", entry);
            const date = entry.date;
            const terminy = entry.poradnie[0].specjalizacje[0].pracownicy[0].terminy;
            table.push({ date, terminy });
        });

        table.forEach((item) => {
            timeSlotsByDate[item.date] = item.terminy;
        });

        for (const date in timeSlotsByDate) {
            formattedTimeSlotsByDate[date] = timeSlotsByDate[date].map((time) =>
                time.slice(0, 5),
            );
        }

        const timeSlots = formattedTimeSlotsByDate[formattedDate] || [];

        const buttons = timeSlots.map((label, index) => (
            <Tag
                type={label === "-" ? "not-available" : "primary"}
                key={label}
                text={label}
                onClick={() => chooseAppointment(label, date, appointment.appointment)}
            />
        ));

        return (
            <div className={`tags_wrapper ${isExpanded ? "tags_wrapper--expanded" : ""}`}>
                {buttons}
            </div>
        );
    };



    const renderFooter = () => {
        const handleShowMoreHoursClick = () => {
            setIsExpanded(!isExpanded);
        };

        let buttonText;
        if (!isExpanded) {
            buttonText = (
                <React.Fragment>
                    <box-icon name='chevron-down'></box-icon>
                    {t(`show_more_hours`)}
                    <box-icon name='chevron-down'></box-icon>
                </React.Fragment>
            );
        } else {
            buttonText = <React.Fragment>
                <box-icon name='chevron-up' ></box-icon>
                {t(`show_less_hours`)}
                <box-icon name='chevron-up' ></box-icon>
            </React.Fragment>
        }

        return (
            <div className="hour-picker__footer">
                <Divider />
                <Button
                    id={"hour-picker-button"}
                    type={ButtonType.Filled}
                    color={ButtonColor.Gray}
                    shape={ButtonShape.Rounded}
                    text={buttonText}
                    onClick={handleShowMoreHoursClick}
                />
            </div>
        );
    };

    return (
        <div className="hour-picker">
            {renderHeader()}
            <div className="hour-picker__content">
                <div className={`icon icon--prev ${disablePrevButton ? "icon--prev--disabled" : ""}`} onClick={() => changeWeekHandle("prev")} disabled={disablePrevButton}></div>
                <div className="days-wrapper">
                    {renderDays()}
                    {renderCells()}
                </div>
                <div className="icon icon--next" onClick={() => changeWeekHandle("next")} />
            </div>
            {renderFooter()}
        </div>
    );
};

export default HourPicker;