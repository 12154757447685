import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../Hooks/useAuthorization";
import { useAppointment } from "../../Hooks/useAppointment";

import ExtraActions from "../../Components/ExtraActions/ExtraActions";
import { Butt } from "@vectopus/atlas-icons-react";
import { Page } from "../../Components/Page/Page";
import Header from "../../Components/Header/Header";
import SelectInput from "../../Components/Select/SelectInput";
import Form from "../../Components/Form/Form";
import FormRow from "../../Components/Form/FormRow";
import TextInput from "../../Components/TextInput/TextInput";
import FormRowItem from "../../Components/Form/FormRowItem";
import Button, { ButtonType, ButtonColor, ButtonShape, ButtonSize } from "../../Components/Button/Button";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Footer } from "../../Components/Footer/Footer";
import Divider from "../../Components/Divider/Divider";
import CardHeader from "../../Components/Card/CardHeader/CardHeader";
import { ContentCard } from "../../Components/ContentCard/ContentCard";
import Tag from "../../Components/Tag/Tag";
import DateTagSelect from "../../Components/DateTagSelect/DateTagSelect";
import BigIcon from "../../Components/BigIcon/BigIcon";
import { ReactComponent as Dojazd } from "../../Resources/dojazd.svg";
import { ReactComponent as Udogodnienia } from "../../Resources/udogodnienia.svg";
import { ReactComponent as Parking } from "../../Resources/parking.svg";
import { Modal } from "antd";

export default function AppointmentDetails(){
    const [changePassword, setChangePassword] = useState(false);
    const [termsOfUse, setTermsOfUse] = useState(false);
    const [hasPesel, setHasPesel] = useState(true);
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");

    const { user, update } = useAuthorization();
    const { makeAppointment } = useAppointment();
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hour, date, appointment } = location.state;

    const peselRegex = /^\d{11}$/;
    const details = {
        name: appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].imie + " " + appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].nazwisko,
        title: appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].tytul,
        service: appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].usluga.nazwa,
        price: appointment.dates[0].poradnie[0].specjalizacje[0].pracownicy[0].usluga.kwota + " zł"
    };

    const success = () => {
        Modal.success({
            title: "Twoja operacja się powiodła!",
            content: "Niedługo otrzymasz od nas wiadomość SMS potwierdzającej twoją wizytę.",
            onOk: () => { navigate("/"); },
        });
    };

    const failure = () => {
        Modal.error({
            title: "Coś poszło nie tak!",
            content: "Prosimy o skontaktowanie się z rejestracją pod numerem: +48 422 080 100.",
        });
    };

    const warning = () => {
        Modal.warning({
            title: "Ups! Chyba coś pominąłeś ...",
            content: "Prosimy o sprawdzenie wszystkich wymaganych pól w formularzu.",
        });
    };

    const isPesel = () => {
        Modal.warning({
          title: "Ups! Chyba coś pominąłeś ...",
          content: "Prosimy o sprawdzenie czy wprowadzony numer pesel jest poprawny.",
        });
    };

    const handleChangePassword = () => { setChangePassword(!changePassword); };
    const handleChangeTermsOfUse = () => { setTermsOfUse(!termsOfUse); };
    const handleChangeHasPesel = () => { setHasPesel(!hasPesel); };

    const scheduleAppointment = async () => {
        try{
            if (!termsOfUse || !user.name || !user.surname || !user.phone || !(user.pesel || user.birthday)) { warning(); return; } 
            if (!peselRegex.test(user.pesel)) { isPesel(); return; }
            if (password !== repassword) { throw new Error('Brak zgodności haseł.'); }
            const response = await makeAppointment(appointment, hour, date, user);
            // if(changePassword){ const result = await signup(user.name, user.surname, user.pesel, user.email, password); }
            success();
        } catch (error) {
            console.log("Error(scheduleAppointment): ", error.message);
            failure();
        }
    };

    const pageTitle = (
        <Header
          level={1}
          title={t(`appointment_details`)}
          subtitle={t(`appointment_details_description`)}
          icon={<box-icon name="calendar"></box-icon>}
        />
    );
    
    const formHeader = (
        <Header
          level={3}
          title={t(`appointment_details`)}
          subtitle={t(`appointment_details_description`)}
        />
    );
    
    const Sidebar = () => (
        <iframe
          className="map_container"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4149.139326229852!2d19.42346990675801!3d51.80446122374608!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471bcb47d1cfaf0b%3A0xa48ddc934e77ab3e!2sPUROMED%20Poradnia%20Lekarzy%20Specjalist%C3%B3w!5e0!3m2!1spl!2spl!4v1696438821883!5m2!1spl!2spl"
          width="448"
          height="627"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    ); 

    const mainPart = (
        <React.Fragment>
            <div className="content-wrapper">
                <div className="control-group--classic">
                    <ContentCard className={"details-card"}>
                        <CardHeader name={details.name} title={details.title} standalone />
                    </ContentCard>
                
                    <ContentCard className={"details-card"}>
                        <SelectInput id="#" disabled={true} append={<Tag type="tertiary" text={details.price} />} value={details.service} />
                    </ContentCard>
        
                    <ContentCard className={"details-card"}>
                        <DateTagSelect scheduledVisit date={date} hour={hour} />
                    </ContentCard>          
                </div>
                <Divider />
                <div className="main-container">
                    <Form
                        header={formHeader}
                        onSubmit={scheduleAppointment}
                    >
                        <FormRow>
                            <TextInput
                                label={t(`name`)}
                                placeholder={t(`name_input_placeholder`)}
                                name='name'
                                value={user.name}
                                onChange={update}
                                required
                            />
                            <TextInput
                                label={t(`surname`)}
                                placeholder={t(`surname_input_placeholder`)}
                                name='surname'
                                value={user.surname}
                                onChange={update}
                                required
                            />
                        </FormRow>

                        <FormRow>
                            <TextInput
                                label={t(`phone`)}
                                placeholder={t(`phone_input_placeholder`)}
                                name='phone'
                                value={user.phone}
                                onChange={update}
                                required
                            />
                            <TextInput
                                label={t(`email`)}
                                placeholder={t(`email_input_placeholder`)}
                                name='email'
                                value={user.email}
                                onChange={update}
                            />
                        </FormRow>

                        {hasPesel && (
                            <FormRow>
                                <TextInput
                                    label={t(`pesel`)}
                                    placeholder={t(`pesel_input_placeholder`)}
                                    name='pesel'
                                    value={user.pesel}
                                    onChange={update}
                                    required
                                />
                            </FormRow>
                        )}

                        {!hasPesel && (
                            <FormRow>
                                <TextInput
                                    label={t(`date_of_birth`)}
                                    placeholder={t(`date_of_birth_placeholder`)}
                                    name='birthday'
                                    value={user.birthday}
                                    onChange={update}
                                    required
                                />
                            </FormRow>
                        )}
                        
                        <FormRow>
                            <Checkbox
                                label={t(`dont_have_pesel`)}
                                value={!hasPesel}
                                onChange={handleChangeHasPesel}
                            />
                        </FormRow>
                        
                        <FormRow>
                            <FormRowItem flexBasis={60}>
                                <TextInput
                                    label={t(`street`)}
                                    placeholder={t(`street_input_placeholder`)}
                                    name='street'
                                    value={user.street}
                                    onChange={update}
                                />
                            </FormRowItem>
                            
                            <FormRowItem flexBasis={30}>
                                <TextInput
                                    label={t(`home`)}
                                    placeholder={t(`home_input_placeholder`)}
                                    name='home'
                                    value={user.home}
                                    onChange={update}
                                />
                            </FormRowItem>
                            
                            <FormRowItem flexBasis={10}>
                                <TextInput
                                    label={t(`apartment`)}
                                    placeholder={t(`apartment_input_placeholder`)}
                                    name='apartment'
                                    value={user.apartment}
                                    onChange={update}
                                />
                            </FormRowItem>
                        </FormRow>
                        
                        <FormRow>
                            <FormRowItem>
                                <TextInput
                                    label={t(`postal_code`)}
                                    placeholder={t(`postal_code_input_placeholder`)}
                                    name='code'
                                    value={user.code}
                                    onChange={update}
                                />
                            </FormRowItem>
                            
                            <FormRowItem flexBasis={75}>
                                <TextInput
                                    label={t(`city`)}
                                    placeholder={t(`city_input_placeholder`)}
                                    name='city'
                                    value={user.city}
                                    onChange={update}
                                />
                            </FormRowItem>
                        </FormRow>

                        {/* <FormRow>
                            <Checkbox primary label={t(`create_account`)} value={changePassword} onChange={handleChangePassword} />
                        </FormRow> */}
                        
                        {changePassword &&
                            <FormRow>
                                <TextInput label={t(`change_password`)} placeholder={t(`password_input_placeholder`)} value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </FormRow>
                        }

                        {changePassword &&
                            <FormRow>
                                <TextInput label={t(`password_repeat`)} placeholder={t(`password_repeat_placeholder`)} value={repassword} onChange={(e) => setRepassword(e.target.value)}/>
                            </FormRow>
                        }

                        <FormRow fitContent>
                            <Checkbox
                                label={t(`accept_terms_of_use`)}
                                value={termsOfUse}
                                onChange={handleChangeTermsOfUse}
                            />
                        </FormRow>

                        <FormRow>
                            <Button
                                type={ButtonType.Filled}
                                color={ButtonColor.Primary}
                                shape={ButtonShape.Pill}
                                text={t(`book_appointment`)}
                                submit
                            />

                            <Button
                                type={ButtonType.Filled}
                                color={ButtonColor.Primary}
                                shape={ButtonShape.Pill}
                                text={t(`return`)}
                                onClick={() => navigate("/")}
                            />
                        </FormRow>
                    </Form>
                    <div className="sidebar__container">
                        <Sidebar />
                        <div className="big-icons__container">
                            <BigIcon icon={<Dojazd />} label={t(`easy_commute`)} />
                            <BigIcon icon={<Parking />} label={t(`big_parking`)} />
                            <BigIcon icon={<Udogodnienia />} label={t(`facilities`)} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Navbar profileMenu navbarButton currentPath={location.pathname} />
            <Page
                id="apointmentDetails"
                pageTitle={pageTitle}
                mainPart={mainPart}
                contactCard
            />
            <Footer />
        </React.Fragment>
    );
};