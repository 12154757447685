import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../Configs/urls";
import { AppointmentDTO } from "../Models/AppointmentDto";

export const useAppointment = () => {
    const [appointments, setAppointments] = useState([]);

    const fetchAppointment = async (clinic, service, doctor, date, doctors) => {
        const newSchedule = [];
        if(doctor){
            const response = await axios.get(`${API_URL}/appointment/fetch`, { params: { clinic, service, doctor, date } }); // console.log("fetchAppointment: ", response.data);
            newSchedule.push(new AppointmentDTO(response.data));
        } else {
            for (const doc of doctors) {
                const id = doc.value;

                try{
                    const response = await axios.get(`${API_URL}/appointment/fetch`, { params: { clinic, service, doctor: id, date } }); // console.log("fetchAppointment: ", response.data);
                    const data = new AppointmentDTO(response.data);
                    if (data.dates.length > 0) { newSchedule.push(data); }
                } catch(error){
                    continue;
                }
            }
        }
        newSchedule.sort((a, b) => new Date(a.dates[0].date) - new Date(b.dates[0].date));
        setAppointments(newSchedule); 
    };

    const makeAppointment = async (appointment, hour, date, user) => {
        const response = await axios.post(`${API_URL}/appointment/make`, { appointment, hour, date, user }); //console.log('makeAppointment', response);
        return response.data;
    };

    const confirmAppointment = async (id) => {
        const response = await axios.post(`${API_URL}/appointment/confirm`, { id }); //console.log('confirmAppointment', response);
        return response.data;
    };

    const cancelAppointment = async (id) => {
        const response = await axios.post(`${API_URL}/appointment/cancel`, { id }); //console.log('cancelAppointment', response);
        return response.data;
    };

    const previousAppointment = async (id) => {
        const response = await axios.get(`${API_URL}/appointment/previous`, { params: { id } }); //console.log("previousAppointment", response.data);
        setAppointments(response.data); 
        return response.data;
    };

    const upcomingAppointment = async (id) => {
        const response = await axios.get(`${API_URL}/appointment/upcoming`, { params: { id } }); //console.log("upcomingAppointment", response.data);
        setAppointments(response.data); 
        return response.data;
    };

    const appointmentResults = async (id) => {
        const response = await axios.get(`${API_URL}/appointment/results`, { params: { id } }); //console.log("appointmentResults", response.data);
        setAppointments(response.data); 
        return response.data;
    };

    return { appointments, setAppointments, fetchAppointment, makeAppointment, confirmAppointment, cancelAppointment, previousAppointment, upcomingAppointment, appointmentResults };
};