import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../Configs/urls";

export const useService = (clinic, doctor, visitType) => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            const response = await axios.get(`${API_URL}/service/fetch`, { params: { clinic, doctor, visitType } }); //console.log("services fetch: ", response.data);
            const data = response.data.map((item) => ({ label: item.nazwa, value: item.id, })); //console.log("services augment: ", data);
            // data.push({label: "Dowolna", value: '0'});
            setServices(data);
        };

        fetch();
    }, [clinic, doctor, visitType]);

    return services;
};