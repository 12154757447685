import { useEffect, useState } from "react";

export const useDate = () => {
    const [date, setDate] = useState();

    useEffect(() => {
        const prepare = () => {
            const currentDate = new Date();
  
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
          
            const formattedMonth = month < 10 ? `0${month}` : month;
            const formattedDay = day < 10 ? `0${day}` : day;
          
            const selectedDate = `${year}-${formattedMonth}-${formattedDay}`;
            setDate(selectedDate);
        };

        prepare();
    }, []);

    return [date, setDate];
};