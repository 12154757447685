import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../Configs/urls";

export const useClinic = (service, doctor, visitType) => {
    const [clinics, setClinics] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            const response = await axios.get(`${API_URL}/clinic/fetch`, { params: { service, doctor, visitType } }); //console.log("clinics fetch: ", response.data);
            const data = response.data.map((item) => ({ label: item.nazwa, value: item.id, })); //console.log("clinics augment: ", data);
            // data.push({label: "Dowolna", value: '0'});
            setClinics(data);
        };

        fetch();
    }, [service, doctor, visitType]);

    return clinics;
};