import React from 'react';
import { useParams } from 'react-router-dom';
import { useNotify } from '../../Hooks/useNotify';
import { useAppointment } from '../../Hooks/useAppointment';
import { Flex, Typography, Button } from 'antd';
import "./_confirm.scss";

const { Title } = Typography;

export default function Confirm() {
    const { id } = useParams();
    const { confirmAppointment, cancelAppointment } = useAppointment(); 
    const { contextHolder, notify } = useNotify();
    
    const accept = async () => {
        try{
            const response = await confirmAppointment(id);
            notify('success', "Drogi użytkowniku!", "Dziękujemy za potwierdzenie wizyty w naszej przychodni.");
        }catch(error){
            console.log("Error(accept): ", error.message);
            notify('error', "Coś poszło nie tak ...", "Niestety operacja się nie powiodła prosimy o kontakt z rejestracją.")
        }
    };

    const cancel = async () => {
        try{
            const response = await cancelAppointment(id);
            notify('info', "Drogi użytkowniku!", "Dziękujemy za informację. Twoja wizyta została odwołana.");
        }catch(error){
            console.log("Error(cancel): ", error.message);
            notify('error', "Coś poszło nie tak ...", "Niestety operacja się nie powiodła prosimy o kontakt z rejestracją.")
        }
    };

    return (
        <Flex className="container" justify="center" align="center">
            {contextHolder}
            <Flex className="box">
                <Title level={3}>Czy potwierdzasz swoją wizytę?</Title>
                <Flex gap="large" justify="center">
                    <Button type="primary" className="button --color" onClick={accept}>Tak</Button>
                    <Button className="button" onClick={cancel}>Nie</Button>
                </Flex>
            </Flex>
        </Flex>
    );
}
